import { Row, Col } from "antd"
import Blog from "./blogPage"
import CardH from "../components/cardHorizontal"
import CardV from "../components/cardVertical"

interface BusinessTypeProps {
    type: "desktop" | "web" | "app",
}

const desktopTitle = "电脑软件开发"
const desktopContent = [
        "**蛙跃科技**专注于为科研机构、高校实验室以及个人研究者提供定制化电脑软件开发服务，以满足科研工作中的各种需求。",
        "## 沟通优先",
        "科研领域的工作具有创新性，对软件的定制化要求较高。因此我们非常注重前期的沟通，倾听并分析用户的想法和需求，提供高质量的设计方案。",
        "## 质量至上",
        "凭借多年软件定制开发经验，我们对不同领域的科研工作者的需求有一定的了解，能够从软件开发的角度给研究者提供协助。",
        "无论您使用的是Windows、麒麟操作系统、MacOS还是其他操作系统，我们都能为您量身定制开发适用的软件。凭借多年的软件定制开发经验，我们深知不同领域的研究者所需的功能和性能要求，能够快速理解并满足客户的需求。我们的团队由经验丰富、技术娴熟的开发人员组成，拥有卓越的技术能力和创新思维。无论您的项目规模如何，我们都能够提供高效、可靠的解决方案，并确保交付高质量的软件产品。在**蛙跃**，我们不仅注重技术，更注重与客户的沟通与合作。我们将倾听您的需求和想法，与您密切合作，确保最终交付的软件完全符合您的期望。如果您正在寻找一家可靠的合作伙伴来开发定制化的科研领域电脑软件，**蛙跃科技**将是您的首选。期待与您携手合作，共同推动科研工作取得更大的成就！",
        "## 多平台、多语言支持",
        "多平台的软件定制开发经验，包括Windows、麒麟、Ubuntu、MacOS等。为科研机构、实验室提供高质量的软件定制服务。",
        "## 领域",
        "* **传感器数据采集**",
        "* **通过串口、CAN实现设备控制**",
        "* **AI图像、图纸识别**",
        "## 技术栈",
        "![](oss.png)",
        "### 开发框架",
        "* MFC",
        "* Qt",
        "* Electron",
        "### 开发语言",
        "* C/C++",
        "* Python",
        "* Javascript",
        "* Golang",
        "## 案例",
]

const webTitle = "SaaS与网站开发"
const webContent = [
        "**蛙跃科技**针对科研领域提供**SaaS**服务，同时为科研机构、高校实验室以及个人研究者提供定制化网站开发与备案上线服务。",
        "![](saas.png)",
        "## SaaS服务",
        "SaaS服务也就是*软件即服务*，蛙跃科技提供科研领域SaaS服务，并且支持服务定制化。",
        "当前提供的SaaS服务包括**实验数据管理系统EDM**，具体是实验数据的格式化、保存、权限管理与检索。实现实验室数据的分级授权，**提升数据安全性**；将实验数据整理后保存在数据库，提供可视化的检索服务，提升数据的可用性，最大程度**挖掘数据价值**。",
        "蛙跃科技也可以根据用户需求，定制SaaS服务和网站开发服务。",
        "## 技术栈",
        "### 开发框架",
        "* ReactJS",
        "* Vue",
        "### 开发语言",
        "#### 前端",
        "* Javascript/Typescript",
        "#### 代理",
        "Nginx",
        "#### 后台",
        "* Golang",
        "* Java",
        "* Python",
        "## 案例",
]

const appTitle = "电脑软件开发"
const appContent = [
        "## 技术栈",
        "* Android",
        "* iOS",
]

const type2title = {
    "desktop":desktopTitle,
    "web": webTitle,
    "app":appTitle
}

const type2content = {
    "desktop": desktopContent,
    "web": webContent,
    "app": appContent
};

export default function BusinessType({ type }: BusinessTypeProps) {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Blog title={type2title[type]} >{type2content[type]}</Blog>
            <div style={{ width: "100%", marginBottom: "2rem" }}>
                {
                    type == "desktop" &&
                    <Row style={{ width: "80%", marginLeft: "10%" }}>
                        <Col xxl={3} xl={0} lg={0} md={0} sm={0} xs={0}></Col>
                        <Col xxl={6} xl={8} lg={12} md={12} sm={24} xs={24} style={{ display: "flex", justifyContent: "center" }}>
                            <CardV style={{ height: 200, aspectRatio: 1.6, cursor: "default" }} titleStyle={{ fontSize: '24px', fontStyle: "bold", textAlign: "center" }} title='机械臂数字孪生上位机' content="在上位机软件中嵌入Unity程序，实现机械臂仿真，在无实物的情况下验证控制逻辑与AI算法。连接机械臂后实现机械臂控制的人机交互，同时快速验证AI算法在真实硬件中的表现。"></CardV>
                        </Col>
                        <Col xxl={6} xl={8} lg={12} md={12} sm={24} xs={24} style={{ display: "flex", justifyContent: "center" }}>
                            <CardV style={{ height: 200, aspectRatio: 1.6, cursor: "default" }} titleStyle={{ fontSize: '24px', fontStyle: "bold", textAlign: "center" }} title='传感器信号可视化' content="软件通过串口接收硬件采集的传感器数据，包括超声数据、肌电数据、视觉数据等，绘制传感器数据实现数据可视化与人机交互，方便用户观察相关数据并判断数据质量。"></CardV>
                        </Col>
                        <Col xxl={6} xl={8} lg={12} md={12} sm={24} xs={24} style={{ display: "flex", justifyContent: "center" }}>
                            <CardV style={{ height: 200, aspectRatio: 1.6, cursor: "default" }} titleStyle={{ fontSize: '24px', fontStyle: "bold", textAlign: "center" }} title='芯片良率检测上位机' content="通过GigE接收工业相机采集的芯片图像数据，协助完成数据标注、模型训练以及模型准确率测试。"></CardV>
                        </Col>
                        <Col xxl={3} xl={0} lg={0} md={0} sm={0} xs={0}></Col>
                    </Row>
                }
                {
                    type == "web" &&
                    <Row style={{ width: "80%", marginLeft: "10%" }}>
                        <Col xxl={4} xl={4} lg={0} md={0} sm={0} xs={0}></Col>
                        <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24} style={{ display: "flex", justifyContent: "center" }}>
                            <CardV style={{ height: 200, aspectRatio: 1.6, cursor: "default" }} titleStyle={{ fontSize: '24px', fontStyle: "bold", textAlign: "center" }} title='SaaS: EDMS' content="Experimental Data Management System，也就是实验数据管理系统，协助用户管理实验数据，提升实验数据的安全性、可用性，发掘数据价值。"></CardV>
                        </Col>
                        <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24} style={{ display: "flex", justifyContent: "center" }}>
                            <CardV style={{ height: 200, aspectRatio: 1.6, cursor: "default" }} titleStyle={{ fontSize: '24px', fontStyle: "bold", textAlign: "center" }} title='可信数据记录网站' content="基于区块链的实现数据的可验证性，保证数据不会被篡改；结合分布式数据存储可保证数据的可用性。"></CardV>
                        </Col>
                        <Col xxl={4} xl={4} lg={0} md={0} sm={0} xs={0}></Col>
                    </Row>
                }
                {
                    type == "app" &&
                    <Row style={{ width: "80%", marginLeft: "10%" }}>
                        <Col xxl={3} xl={3} lg={0} md={0} sm={0} xs={0}></Col>
                        <Col xxl={6} xl={8} lg={8} md={8} sm={24} xs={24} style={{ display: "flex", justifyContent: "center" }}>
                            <CardV style={{ height: 200, aspectRatio: 1.6, cursor: "default" }} titleStyle={{ fontSize: '24px', fontStyle: "bold", textAlign: "center" }} title='机械臂数字孪生上位机' content="在上位机软件中嵌入Unity程序，实现机械臂仿真，在无实物的情况下验证控制逻辑与AI算法。连接机械臂后实现机械臂控制的人机交互，同时快速验证AI算法在真实硬件中的表现。"></CardV>
                        </Col>
                        <Col xxl={6} xl={6} lg={8} md={8} sm={24} xs={24} style={{ display: "flex", justifyContent: "center" }}>
                            <CardV style={{ height: 200, aspectRatio: 1.6, cursor: "default" }} titleStyle={{ fontSize: '24px', fontStyle: "bold", textAlign: "center" }} title='传感器信号可视化' content="软件通过串口接收硬件采集的传感器数据，包括超声数据、肌电数据、视觉数据等，绘制传感器数据实现数据可视化与人机交互，方便用户观察相关数据并判断数据质量。"></CardV>
                        </Col>
                        <Col xxl={6} xl={6} lg={8} md={8} sm={24} xs={24} style={{ display: "flex", justifyContent: "center" }}>
                            <CardV style={{ height: 200, aspectRatio: 1.6, cursor: "default" }} titleStyle={{ fontSize: '24px', fontStyle: "bold", textAlign: "center" }} title='芯片良率检测上位机' content="通过GigE接收工业相机采集的芯片图像数据，协助完成数据标注、模型训练以及模型准确率测试。"></CardV>
                        </Col>
                        <Col xxl={3} xl={3} lg={0} md={0} sm={0} xs={0}></Col>
                    </Row>
                }
            </div>
        </div>
    )
}