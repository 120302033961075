import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom"
import Index from "./pages"
import News from "./pages/news"
import Products from "./pages/products"
import BusinessType from "./pages/businessType"
import ErrorPage from "./pages/error"
import PageRoot from "./pages/pageRoot"

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<ErrorPage></ErrorPage>} />
        <Route path="/" element={<PageRoot />}>
          <Route path="/" element={<Navigate to="/index" replace />}></Route>
          <Route path="/index" element={<Index></Index>} errorElement={<ErrorPage></ErrorPage>}></Route>
          <Route path="/products" element={<Products />}></Route>
          <Route path="/news" element={<News />}></Route>
          <Route path="/desktop" element={<BusinessType type="desktop" />}></Route>
          <Route path="/web" element={<BusinessType type="web" />}></Route>
          <Route path="/app" element={<BusinessType type="app" />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
