interface TextWithQuotationProps {
    style?: React.CSSProperties,
    children: string
}
export default function TextWithQuotation({style, children}: TextWithQuotationProps) {
    return (
        <div className="component-textwithquotation-container" style={style}>
            <p className="quotation quotation-left">
                “
            </p>
            <p className="content">
                {children}
            </p>
            <p className="quotation quotation-right">

                ”
            </p>
        </div>
    )
}