import MarkdownBlog from "../components/markdownBlog"

interface BlogProps {
    topImageUrl?: string,
    title?: string,
    subTitle?: string,
    children: Array<string>,
}

export default function Blog({topImageUrl="software-developer.jpg", title = "", subTitle = "",children}: BlogProps) {
    return (
        <div className="page-blog-container">
            <img className="top-image" src={topImageUrl}></img>
            <div className="content">
                <div className="title">
                    {title.length > 0 && <h1>{title}</h1>}
                    {subTitle.length > 0 && <p className="sub-title">{subTitle}</p>}
                </div>
                <MarkdownBlog>
                    {children}
                </MarkdownBlog>
            </div>
        </div>

    )
}