import {SwapRightOutlined} from '@ant-design/icons'
import { useNavigate } from 'react-router'
interface CardVProps {
    style?: React.CSSProperties,
    titleStyle?: React.CSSProperties,
    imgUrl?: string,
    title: string,
    content?: string,
    moreUrl?:string
}
export default function CardV({style, titleStyle, imgUrl, title, content, moreUrl}:CardVProps) {
    const navigate = useNavigate()
    return (
        <div className="component-cardvertical-container" style={style}>
            {imgUrl != null && <img className="image" src={imgUrl}></img>}
            <div className="content-container">
                <p className="title" style={titleStyle}>{title}</p>
                { content != null && <p className="content">{content}</p>}
                {moreUrl != null && <div className="learn-more" onClick={(e) => {
                    navigate(moreUrl)
                }}>
                    <span>查看更多</span>
                    <SwapRightOutlined/>
                </div>}
            </div>
        </div>
    )
}