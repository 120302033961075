interface TextCardProp {
    style?: React.CSSProperties,
    title?: string,
    content?: string,
    detailUrl?: string,
}

export default function TextCard({style, title = "", content = "", detailUrl = "" }: TextCardProp) {
    return (
        <div className="component-textcard-container" style={style}>

            <div className="title">{title}</div>
            <div className="content">{content}</div>
            {detailUrl.length > 0 && <div className="learn-more">
                <a href={detailUrl}>
                    查看更多
                </a>
                <hr style={{ backgroundColor: "white", width: "3rem" }}></hr>
            </div>}
        </div>

    )
}