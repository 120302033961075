import { ReactNode } from "react"

interface DividerWithTextProps {
    style?: React.CSSProperties,
    orientation? : "left" |"right" | "middle"
    children?: ReactNode
}
export default function DividerWithText({style,orientation = "left", children}:DividerWithTextProps) {
    return (
        <div className="component-dividerwithtext-container" style={style}>
            <div style={{ fontSize: "1rem", fontWeight: 800 }} className={'divider-with-text-container divider-with-text-container-' + orientation}>
                <span style={{ whiteSpace: "nowrap" }}>
                    <div style={{ display: "inline-block", padding: "0 1em" }}>
                        {children}
                    </div>
                </span>
            </div>
        </div>
    )
}