
import { Component, ReactNode } from 'react'

export default class Products extends Component {
    render(): ReactNode {
        return (
            <div>
                <h1>Products</h1>
            </div>
        )
    }
}