import { Row, Col, Carousel } from 'antd'
import { StarOutlined} from '@ant-design/icons';
import HistoryLine from '../components/historyLine';
import TextCard from '../components/textCard';
import TextWithQuotation from '../components/textWithQuotation';
import DividerWithText from '../components/dividerWithText';
import CardV from '../components/cardVertical';
import useScreenSize from '../hooks/useScreenSize';

export default function Index() {
  const screenSize = useScreenSize();
  return (
    <div className='page-index-container'>
      <div style={{ height: "fit-content", marginBottom:"10rem"}}>
        {screenSize.width > 950 && <div className='carousel-info-container'>
          <p className='carousel-info-title'>使命</p>
          <p className='carousel-info-content'>让科研人 专注科研</p>
        </div>}
        <Carousel
          className='carousel-container'
          autoplay
          dotPosition='bottom'
        >
          <div className='carousel-item-container'>
            <img
              className='carousel-item-img carousel-item-img-1'
              src='software-developer.jpg'
            />
          </div>
          <div className='carousel-item-container'>
            <img
              className='carousel-item-img carousel-item-img-2'
              src='server-room.jpg'
            />
          </div>
        </Carousel>
        <div style={{display:"flex", justifyContent:"center"}}>
          <TextWithQuotation>我们专注科研领域，提供更符合科研项目要求的软件开发服务</TextWithQuotation>
        </div>
        <DividerWithText orientation='middle' style={{marginLeft:"5rem", marginRight:"5rem"}}>
          <StarOutlined style={{color:"darkgray"}} />
        </DividerWithText>
        <Row style={{paddingLeft:"10%", paddingRight:"10%", paddingBottom:"5rem", paddingTop:"5rem"}}>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
            <TextCard style={{height:150}} title='专家评审' content='积极配合、参与项目各个阶段的评审需求，包括但不限于专家评审、立项评审、中期评审、结题评审等。'></TextCard>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
            <TextCard style={{height:150}} title='技术文档' content='结合项目要求与进度，输出规范的技术文档，方便项目管理、汇报与推进。'></TextCard>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
            <TextCard style={{height:150}} title='知识产权' content='提供软件著作权申报，为项目锦上添花，方便项目的成果展示。'></TextCard>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
            <TextCard style={{height:150}} title='安全保障' content='签订保密协议，配合公司内部完善的保密措施，确保项目与数据的安全性、隐私性。'></TextCard>
          </Col>
        </Row>
        <div>
          <p style={{ fontSize: "3em", fontWeight: 500, marginLeft: "10%" }}>业务范围</p>
          <Row style={{width:"80%", marginLeft:"10%"}}>
            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24} style={{display:"flex", justifyContent:"center"}}>
              <CardV style={{height:500, aspectRatio:0.8}} imgUrl='desktop.jpg' title='电脑软件' content='蛙跃科技拥有多平台的软件定制开发经验，包括Windows、麒麟、Ubuntu、MacOS等。为科研机构、实验室提供高质量的软件定制服务。' moreUrl='/desktop'></CardV>
            </Col>
            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24} style={{display:"flex", justifyContent:"center"}}>
              <CardV style={{height:500, aspectRatio:0.8}} imgUrl='websites.png' title='网站与SaaS' content='蛙跃科技针对科研领域提供SaaS服务，蛙跃科技也拥有多功能、多语言、UI自适应的网站开发经验，提供从UI设计、原型设计到功能实现、部署维护的全套网站开发服务。' moreUrl="/web"></CardV>
            </Col>
            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24} style={{display:"flex", justifyContent:"center"}}>
              <CardV style={{height:500, aspectRatio:0.8}} imgUrl='phone-apps.jpg' title='手机APP' content='蛙跃科技有来自国内大厂的客户端开发人员，能够针对安卓、鸿蒙、iOS等多平台定制开发高质量手机APP，并提供上架、维护服务'></CardV>
            </Col>
          </Row>
        </div>
        <div>
          <p style={{ fontSize: "3em", fontWeight: 500, marginLeft: "10%" }}>技术亮点</p>
          <Row style={{width:"80%", marginLeft:"10%"}}>
            <Col xxl={3} xl={3} lg={0} md={0} sm={0} xs={0}></Col>
            <Col xxl={6} xl={6} lg={8} md={8} sm={24} xs={24} style={{display:"flex", justifyContent:"center"}}>
              <CardV style={{height:150, aspectRatio:1.6, cursor:"default"}} titleStyle={{fontSize: '24px', fontStyle: "bold",textAlign:"center"}} title='➕AI' content="将AI能力嵌入应用软件中，以AI赋能软件应用的同时，为AI提供可视化交互。"></CardV>
            </Col>
            <Col xxl={6} xl={6} lg={8} md={8} sm={24} xs={24} style={{display:"flex", justifyContent:"center"}}>
              <CardV style={{height:150, aspectRatio:1.6, cursor:"default"}} titleStyle={{fontSize: '24px', fontStyle: "bold", textAlign:"center"}} title='➕硬件' content='在软件中增加硬件交互、硬件模拟能力，实现人机交互，降低项目成本。'></CardV>
            </Col>
            <Col xxl={6} xl={6} lg={8} md={8} sm={24} xs={24} style={{display:"flex", justifyContent:"center"}}>
              <CardV style={{height:150, aspectRatio:1.6, cursor:"default"}} titleStyle={{fontSize: '24px', fontStyle: "bold", textAlign:"center"}} title='➕数据' content='大规模实验数据的存储、管理与检索使用，提升实验数据的使用效率，减少重复实验。'></CardV>
            </Col>
            <Col xxl={3} xl={3} lg={0} md={0} sm={0} xs={0}></Col>
          </Row>
        </div>
        {false && <div>
          <p style={{ fontSize: "3em", fontWeight: 500, marginLeft: "10%" }}>重要事件</p>
          <HistoryLine events={[
            { icon: "importent.png", imgUrl: "buiding.jpg", title: "2023年9月5日", content: "上海蛙跃科技有限公司成立" },
            { icon: "importent.png", imgUrl: "working.jpg", title: "2023年11月1日", content: "上海蛙跃科技有限公司官方网站发布" },
          ]}></HistoryLine>
        </div>}
        <div style={{marginLeft: "10%"}}>
          <p style={{ fontSize: "3em", fontWeight: 500, marginLeft: "0%" }}>联系我们</p>
          <Row style={{ marginLeft: "0%" }}>
            {/* <Col span={8}> */}
            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
              <p style={{ fontSize: "2em", fontWeight: 500, marginLeft: "10%" }}>商业合作</p>
              <a style={{ fontSize: "1.5em", fontWeight: 500, marginLeft: "10%" }} href='Mailto:business@wayuekeji.com'>business@wayuekeji.com</a>
            </Col>
            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
              <p style={{ fontSize: "2em", fontWeight: 500, marginLeft: "10%" }}>加入我们</p>
              <a style={{ fontSize: "1.5em", fontWeight: 500, marginLeft: "10%" }} href='Mailto:hr@wayuekeji.com'>hr@wayuekeji.com</a>
            </Col>
            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
              <p style={{ fontSize: "2em", fontWeight: 500, marginLeft: "10%" }}>企业微信</p>
              <img style={{ fontSize: '2em', fontWeight: 500, marginLeft: "10%", width: 200}} src="wechat_b_qrcode.png" />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}