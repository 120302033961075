import Markdown from "react-markdown";
interface MarkdownBlogProps {
    children: Array<string>,
}

export default function MarkdownBlog({ children }: MarkdownBlogProps) {
    return (
        <div>
            {children.map((line, index) => (
                <Markdown
                    components={{
                        h1(props) {
                            const { node, ...rest } = props
                            return (
                                <div className="markdown-h1-container">
                                    <h1 {...rest}></h1>
                                </div>
                            )
                        },
                        h2(props) {
                            const { node, ...rest } = props
                            return (
                                <div className="markdown-h2-container">
                                    <h2 {...rest}></h2>
                                </div>
                            )
                        },
                        h3(props) {
                            const { node, ...rest } = props
                            return (
                                <div className="markdown-h3-container">
                                    <h3 {...rest}></h3>
                                </div>
                            )
                        },
                        img(props) {
                            console.log(props)
                            const { src, ...rest } = props
                            return (
                                <div className="markdown-img-container">
                                    <img src={src}></img>
                                </div>
                            )
                        }
                    }}
                >{line}</Markdown>
            ))}
        </div>
    )
}