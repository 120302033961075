import { Result, Button } from 'antd'
import { useNavigate } from 'react-router'

export default function ErrorPage() {
  const navigate = useNavigate()
    return (
      <div>
        <Result
          status="404"
          title="404"
          subTitle="你访问的页面不存在!"
          extra={<Button type="primary" onClick={(e)=>{
            navigate("index")
          }}>回到主页</Button>}
        />
      </div>
    )
}