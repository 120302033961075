import { Component, ReactNode } from "react";
import { Col, Row } from 'antd';

export default class Footer extends Component {
    render(): ReactNode {
        return (
            <div className="component-footer-container">
                <Row style={{width:"100%"}}>
                    <Col span={8}>
                        <div style={{height:"100%", display:"flex", alignItems:"center", justifyContent:"left"}}>
                            <div className='footer-logo'>
                                <p className="footer-logo-text">蛙跃科技</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={16}>
                        <div className="copy-right-container">
                            <p style={{ margin: 0 }}>© 2023-2024 蛙跃科技</p>
                            <div className="beian">
                                <img style={{ width: "20px", height: "20px" }} src="110.png"></img>
                                <p style={{ margin: 0 }}>
                                    <a style={{ color: "white" }} href="https://beian.mps.gov.cn/#/query/webSearch?code=31011202020017">沪公网安备31011202020017</a>
                                    <a style={{}} href="https://beian.miit.gov.cn/">沪ICP备2023027781号-1</a>
                                </p>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
        )
    }
}