import StickyHeader from "../components/header";
import Footer from "../components/footer";
import useScreenSize from "../hooks/useScreenSize";
import { Divider } from "antd";
import { useState, useEffect } from "react";
import { Outlet } from "react-router";

export default function PageRoot() {
    const screesize = useScreenSize();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
  })
    return (
        <div className="page-root-container" style={{minHeight:screesize.height}}>
            <div className='bg-ellipse-full-wrapper'>
                <div className='bg-ellipse bg-ellipse-1' style={{ transform: `translate3d(0px, ` + String(scrollPosition / 10) + `px, 0px)` }}></div>
                <div className='bg-ellipse bg-ellipse-2' style={{ transform: `translate3d(0px, ` + String(scrollPosition / 10) + `px, 0px)` }}></div>
                <div className='bg-ellipse bg-ellipse-3' style={{ transform: `translate3d(0px, ` + String(scrollPosition / 10) + `px, 0px)` }}></div>
            </div>
            <StickyHeader></StickyHeader>
            <div className="sub-page-container" style={{minHeight:"80vh"}}>
                <Outlet></Outlet>
            </div>
            <Footer></Footer>
        </div>
    )
}