import React from 'react';
import logo from './logo.svg';
import './App.scss';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import Router from "./router"

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
