import MediaQuery from 'react-responsive';
import {MenuOutlined} from "@ant-design/icons"
import { useNavigate } from "react-router-dom";
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import useScreenSize from '../hooks/useScreenSize';
/*
 * 自己实现的useScreenSize和react-responsive都可以实现根据屏幕尺寸显示不同内容的目的
 */

export default function StickyHeader() {
    const screenSize = useScreenSize();
    const navigate = useNavigate()
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div className='header-menu-item' onClick={(e) => {
                    navigate("/index")
                }}>
                    关于我们
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div className='header-menu-item' onClick={(e) => {
                    navigate("/projects")
                }}>
                    我们的产品
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div className='header-menu-item' onClick={(e) => {
                    navigate("/news")
                }}>
                    新闻动态
                </div>
            ),
        },
    ];

    return (
        <div className="component-header-container">
            <div className='header-logo' onClick={(e)=>{
                navigate("/index")
            }}>
                <img className="header-logo-img" src="logo-latest.svg"/>
                <p className="header-logo-text">蛙跃科技</p>
            </div>
            <MediaQuery minWidth={950} minResolution={"1dppx"}>
                <div className='header-menu'>
                    <div className='header-menu-item' onClick={(e) => {
                        navigate("/index")
                    }}>
                        关于我们
                    </div>
                    <div className='header-menu-item' onClick={(e) => {
                        navigate("/projects")
                    }}>
                        我们的产品
                    </div>
                    <div className='header-menu-item' onClick={(e) => {
                        navigate("/news")
                    }}>
                        新闻动态
                    </div>
                </div>
            </MediaQuery>
            {screenSize.width < 950 && false && <div className='small-menu-icon'>
                <Dropdown menu={{ items }} trigger={['click']}>
                    <MenuOutlined />
                </Dropdown>
            </div>}
        </div>
    )
}